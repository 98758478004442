"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.graphConfig = exports.loginRequest = exports.msalConfig = void 0;
var msal_browser_1 = require("@azure/msal-browser");
// Config object to be passed to Msal on creation
exports.msalConfig = {
    auth: {
        clientId: "cee6359b-c486-4911-8a69-4646062045c4",
        authority: "https://sts.windows.net/b99e7c1b-6a77-4194-aeb8-db80931e4d15/v2.0",
        redirectUri: "https://webexscreenpop-dev.rdoequipment.com.au/",
    },
    cache: {
        cacheLocation: "localStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        allowNativeBroker: false, // Disables WAM Broker
        loggerOptions: {
            loggerCallback: function (level, message, containsPii) {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case msal_browser_1.LogLevel.Error:
                        console.error(message);
                        return;
                    case msal_browser_1.LogLevel.Info:
                        console.info(message);
                        return;
                    case msal_browser_1.LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case msal_browser_1.LogLevel.Warning:
                        console.warn(message);
                        return;
                }
            },
        },
    },
};
// Add here scopes for id token to be used at MS Identity Platform endpoints.
exports.loginRequest = {
    scopes: ["api://au-rdo-func-webexclient-aueast-dev.azurewebsites.net/access", "User.Read"],
};
// Add here the endpoints for MS Graph API services you would like to use.
exports.graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
};
